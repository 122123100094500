import { CanActivateFn, Router } from '@angular/router';
import { HttpRequestsService } from '../../../../../core/httpRequests/http-requests.service';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export const activeLinkCheckGuard: CanActivateFn = (route, state) => {
  const httpClientService = inject(HttpRequestsService);
  const router = inject(Router);
  const { database, uuid } = route.params;

  return httpClientService.linkValidity(database, uuid).pipe(
    map(bk_res => {
      if (bk_res.response.valid_link) {
        return true;
      } else {
        router.navigate(['/404']); 
        return false;
      }
    }),
    catchError(error => {
      console.error('Error occurred while checking link validity', error);
      router.navigate(['/404']);
      return of(false);
    })
  );
};

import { ResolveFn } from '@angular/router';
import { HttpRequestsService } from '../../../../../core/httpRequests/http-requests.service';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { CriteriaResponse } from '../../../../../core/interfaces/ApiResponse.interface';

export const criteriaResolver: ResolveFn<CriteriaResponse | null> = (route, state) => {
  const httpClientService = inject(HttpRequestsService);
  const router = inject(Router);
  const { database, uuid } = route.params;

  return httpClientService.getCriteria(database, uuid).pipe(
    map(bk_res => {
      return bk_res;
    }),
    catchError(error => {
      console.error('Error occurred while fetching criteria', error);
      router.navigate(['/404']);
      return of(null);
    })
  );
};

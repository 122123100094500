import { Routes } from '@angular/router';
import { activeLinkCheckGuard } from './home/components/assessment/guards/active-link-check.guard';
import { criteriaResolver } from './home/components/assessment/resolvers/criteria-resolver.resolver';

export const routes: Routes = [

    {
        path: ':database/:uuid',
        loadComponent: () => import('./home/components/assessment/assessment.component').then(c => c.AssessmentComponent),
        canActivate: [activeLinkCheckGuard],
        resolve: {
            criteria_data: criteriaResolver
        }
    },
    {
        path:':database/:uuid/feedback',
        loadComponent: () => import('./global_components/feedback-message/feedback-message.component').then(c => c.FeedbackMessageComponent)
    },
    {
        path: '**',
        loadComponent: () => import('./global_components/404/404.component').then(c => c.NotFoundComponent)
    }
];
